import useAuthStore from '~/store/auth'
import type { PERMISSIONS } from '~/types/permissions'

export const usePermissionCheck = async (enabled: boolean = true) => {
  const { data: user, suspense } = useCurrentUser(enabled)

  if (enabled) {
    await suspense()
  }

  const { isSuperAdmin } = storeToRefs(useAuthStore())

  const check = (permission: PERMISSIONS[], disableRolePrivileges: boolean = false) => {
    if (!user.value) {
      return false
    }

    // TODO: check this point with BE team
    if (isSuperAdmin.value && !disableRolePrivileges) {
      return true
    }

    return user.value.permissions?.some(userPermission => permission.includes(userPermission.name))
  }

  return {
    check,
  }
}
